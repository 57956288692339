/** @jsx jsx */
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Flex, Heading, jsx, Spinner, Text } from 'theme-ui'
import { useProduct, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import FormInputWithLabel from '~/components/Generic/Form/InputWithLabel'
import { addUserToKlaviyoList } from '~/services/klaviyo/client'

const ProductOutOfStock = ({ sku }) => {
  const translate = useTranslate()
  const { createStockRequest } = useProduct()
  const [loading, setLoading] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  const { register, handleSubmit, errors } = useForm()

  const onSubmit = async (data) => {
    const { email } = data

    setLoading(true)
    try {
      addUserToKlaviyoList(
        { $email: email },
        process.env.GATSBY_KLAVIYO_LIST_ID_NEWSLETTER
      )
      await createStockRequest({ email, product: { variant: { sku } } })
      setSubmitted(true)
    } catch (error) {
      console.error(error, { source: 'ProductOutOfStock', sku })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box bg="white" p={3}>
      {!submitted && (
        <Fragment>
          <Heading as="p" variant="title4">
            {translate('product.notify_when_available.title')}
          </Heading>
          <Text as="p" variant="body2">
            {translate('product.notify_when_available.description')}
          </Text>
          <Box
            as="form"
            data-testid="form"
            onSubmit={handleSubmit(onSubmit)}
            mt={3}
          >
            <Flex
              sx={{
                flexDirection: ['column', null, null, 'row'],
                justifyContent: 'space-between',
              }}
            >
              <Box
                mr={[0, null, null, 2]}
                mb={[2, null, null, 0]}
                sx={{ flexGrow: '1' }}
              >
                <FormInputWithLabel
                  title={translate('your_email')}
                  label={translate('your_email')}
                  aria-label={translate('your_email')}
                  placeholder={translate('your_email')}
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: translate('validation.invalid_email'),
                    },
                  })}
                  name="email"
                  sx={{ width: '100%' }}
                />
              </Box>
              <Button>
                {loading && (
                  <Spinner
                    size="15"
                    color="inherit"
                    sx={{
                      '@media (prefers-reduced-motion)': {
                        circle: {
                          animationIterationCount: 8,
                        },
                      },
                    }}
                  />
                )}
                {!loading && (
                  <Text>
                    {translate('product.notify_when_available.button')}
                  </Text>
                )}
              </Button>
            </Flex>
            {errors && errors['email'] && errors['email'].type === 'required' && (
              <Text as="p" mt={1} variant="error">
                {translate('validation.required_field')}
              </Text>
            )}
            {errors && errors['email'] && errors['email'].type === 'pattern' && (
              <Text as="p" mt={1} variant="error">
                {translate('validation.invalid_email')}
              </Text>
            )}
          </Box>
        </Fragment>
      )}

      {submitted && (
        <Box>
          <Heading as="p" variant="title4">
            {translate('product.notify_when_available.confirm_title')}
          </Heading>
          <Text as="p" variant="text.body2">
            {translate('product.notify_when_available.confirm_description')}
          </Text>
        </Box>
      )}
    </Box>
  )
}

ProductOutOfStock.propTypes = {
  sku: PropTypes.string.isRequired,
}

export default ProductOutOfStock
