import React from 'react'
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import MerchPage from '~/components/Merch/Page'

const MerchTemplate = ({ data }) => {
  const { merch } = data
  const { mainImage, name, noindex, shortDescription } = merch

  return (
    <Layout>
      <Metadata
        title={name}
        description={shortDescription}
        image={getSrc(mainImage)}
        noindex={noindex}
      />
      <MerchPage merch={merch} />
    </Layout>
  )
}

export const query = graphql`
  query MerchQuery($slug: String, $locale: String) {
    merch: contentfulMerch(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...MerchPageFragment
    }
  }
`

export default MerchTemplate
