/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { useState } from 'react'
import { Box, Grid, jsx } from 'theme-ui'
import Thumbnail from '~/components/Generic/Thumbnail'

const MerchImages = ({ images, merch }) => {
  const [selectedImage, setSelectedImage] = useState(images[0])

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <GatsbyImage
        image={selectedImage.gatsbyImageData}
        alt={selectedImage.description}
      />
      {images.length > 1 && (
        <Grid gap={1} columns={4} mt={1}>
          {images.map((image, index) => (
            <Thumbnail
              key={index}
              image={image}
              handleClick={setSelectedImage}
              selected={selectedImage.id === image.id}
            />
          ))}
        </Grid>
      )}
    </Box>
  )
}

export default MerchImages
