/** @jsx jsx */
import { Fragment, useState } from 'react'
import { Box, Button, Flex, Grid, Heading, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import ListingAddToCartButton from '~/components/Listing/AddToCart/Button'
import GiftCardForm from '~/components/Merch/Page/Details/AddToCart/GiftCardForm'
import CheckmarkIcon from '~/assets/images/icons/checkmark.inline.svg'
import { toUsdCurrency } from '~/utils/formatters'
import { getAvailableMerchSkus } from '~/utils/merch'

const MerchDetailsAddToCart = ({ merch }) => {
  const availableSkus = getAvailableMerchSkus(merch)
  const [currentSku, setCurrentSku] = useState(availableSkus[0])
  const translate = useTranslate()

  const isGiftCard = merch?.product?.giftCard

  if (!currentSku) {
    return <Fragment />
  }

  const { sku } = currentSku

  return (
    <Fragment>
      {availableSkus.length > 1 && (
        <Box>
          <Flex
            sx={{
              display: ['block', 'flex'],
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Heading as="h3" mr={3} variant="text.title6">
              {`${
                !isGiftCard
                  ? translate('product.select_quantity')
                  : translate('gift_card.select_amount')
              }:`}
            </Heading>
          </Flex>
          <Grid columns={availableSkus.length} gap={[2, 3]} mt={3} mb={2}>
            {availableSkus.map((sku) => (
              <Button
                key={`${sku.sku}`}
                onClick={(e) => setCurrentSku(sku)}
                variant="productAdd"
                sx={{
                  alignItems: 'center',
                  borderColor: sku === currentSku ? 'black' : null,
                  justifyContent: 'center',
                  position: 'relative',
                  textAlign: 'center',
                }}
                tabIndex={0}
              >
                {toUsdCurrency(sku.price)}
                {sku === currentSku && (
                  <Box
                    sx={{
                      backgroundColor: 'black',
                      borderRadius: '50%',
                      height: '24px',
                      position: 'absolute',
                      right: '-9px',
                      top: '-9px',
                      width: '24px',
                    }}
                  >
                    <CheckmarkIcon
                      sx={{
                        color: 'white',
                        position: 'relative',
                        top: '1px',
                      }}
                    />
                  </Box>
                )}
              </Button>
            ))}
          </Grid>
        </Box>
      )}
      <Box>
        {!isGiftCard && <ListingAddToCartButton showPrice={true} sku={sku} />}
        {isGiftCard && (
          <Box mt={3}>
            <GiftCardForm sku={sku} quantity={1} />
          </Box>
        )}
      </Box>
    </Fragment>
  )
}

export default MerchDetailsAddToCart
