/** @jsx jsx */
import { useState } from 'react'
import { Box, Button, Grid, jsx, Spinner, Text } from 'theme-ui'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { useCartFlyout } from '~/hooks/components/use-cart-flyout'
import { useGiftCards } from '~/hooks/components/use-gift-cards'
import FormInput from '~/components/Generic/Form/Input'
import FormTextarea from '~/components/Generic/Form/Textarea'

const GiftCardForm = ({ sku, quantity }) => {
  const { openCartFlyout } = useCartFlyout()
  const { errors, getValues, handleSubmit, register } = useForm()
  const { addGiftCardToCart } = useGiftCards()
  const [apiError, setApiError] = useState(null)
  const [buttonIsLoading, setButtonIsLoading] = useState(false)
  const translate = useTranslate()

  const onSubmit = async () => {
    setButtonIsLoading(true)
    setApiError(null)

    try {
      const giftCardDetails = getValues()
      await addGiftCardToCart(sku, quantity, giftCardDetails)
    } catch (error) {
      setApiError(translate('error.api.default'))
      console.error(error, {
        source: 'GiftCardForm.onSubmit.addGiftCardsToCart',
      })
    }

    openCartFlyout()
    setButtonIsLoading(false)
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Box mb={2}>
        <FormInput
          name="purchaserName"
          title={translate('gift_card.purchaser_name')}
          label={translate('gift_card.purchaser_name')}
          aria-label={translate('gift_card.purchaser_name')}
          required={true}
          type="text"
          error={errors?.purchaserName?.message}
          ref={register({
            required: {
              value: true,
              message: translate('validation.required_field'),
            },
          })}
        />
      </Box>
      <Grid columns={[null, null, null, 2]} sx={{ rowGap: 0 }}>
        <Box mb={2}>
          <FormInput
            name="recipientEmail"
            title={translate('gift_card.email')}
            label={translate('gift_card.email')}
            aria-label={translate('gift_card.email')}
            required={true}
            type="email"
            error={errors?.recipientEmail?.message}
            ref={register({
              required: {
                value: true,
                message: translate('validation.required_field'),
              },
              pattern: {
                value: /^\S+@\S+$/i,
                message: translate('validation.invalid_email'),
              },
            })}
          />
        </Box>
        <Box mb={2}>
          <FormInput
            name="sendEmailAt"
            title={translate('gift_card.date')}
            label={translate('gift_card.date')}
            aria-label={translate('gift_card.date')}
            type="date"
            ref={register()}
            defaultValue={moment().format('YYYY-MM-DD')}
          />
        </Box>
      </Grid>
      <Box mb={3}>
        <FormTextarea
          name="giftMessage"
          title={translate('gift_card.gift_message')}
          label={translate('gift_card.gift_message')}
          aria-label={translate('gift_card.gift_message')}
          ref={register()}
        />
      </Box>
      <Button
        type="submit"
        variant="primary"
        sx={{
          width: '100%',
          display: 'block',
        }}
      >
        {buttonIsLoading && (
          <Spinner
            data-testid="spinner"
            size="24"
            color="inherit"
            mb={-1}
            sx={{
              position: 'relative',
              top: '-2px',
              '@media (prefers-reduced-motion)': {
                circle: {
                  animationIterationCount: 8,
                },
              },
            }}
          />
        )}
        {!buttonIsLoading && (
          <Text as="span">{translate('product.add_to_cart')}</Text>
        )}
      </Button>
      {apiError && (
        <Text as="p" color="error" variant="formError" mt={2}>
          {apiError.toString()}
        </Text>
      )}
    </Box>
  )
}

export default GiftCardForm
