/** @jsx jsx */
import { Fragment } from 'react'
import { jsx } from 'theme-ui'
import ContentSection from '~/components/Content/Section'
import MerchDetails from './Details'
import Reviews from '~/components/Reviews'

const MerchPage = ({ merch }) => {
  const { sections, slug } = merch

  return (
    <Fragment>
      <MerchDetails merch={merch} />
      {sections &&
        sections.map((section) => {
          return <ContentSection key={section.id} section={section} />
        })}
      <Reviews id={slug} />
    </Fragment>
  )
}

export default MerchPage
