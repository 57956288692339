/** @jsx jsx */
import { Fragment, useEffect } from 'react'
import { Box, Container, Heading, Text, jsx } from 'theme-ui'
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'
import ImageCarousel from '~/components/Generic/ImageCarousel'
import MerchDetailsAddToCart from './AddToCart'
import MerchImages from '~/components/Merch/Page/Details/Images'
import ProductOutOfStock from '~/components/Product/OutOfStock'
import LinkedReviewStars from '~/components/Reviews/LinkedStars'
import { useCanClub } from '~/hooks/components/use-can-club'
import { useCatalog } from '~/hooks/components/use-catalog'
import { getAvailableMerchSkus } from '~/utils/merch'
import { getViewedProductFromSlug } from '~/utils/products'

const MerchDetails = ({ merch }) => {
  const { trackProductViewed } = useAnalytics()
  const { clubDiscount } = useCanClub()
  const { catalog } = useCatalog()

  const { images, longDescription, mainImage, name, slug, soldOut, subtitle } =
    merch
  let availableSkus = getAvailableMerchSkus(merch)

  const merchImages =
    images && images.length ? [mainImage, ...images] : [mainImage]

  const viewedProduct = getViewedProductFromSlug(catalog, slug)

  useEffect(() => {
    trackProductViewed({ product: viewedProduct })
  }, [trackProductViewed, viewedProduct])

  return (
    <Container>
      <Box
        pb={[7, 9, null, 10]}
        pt={[0, 7, null, 9]}
        sx={{
          display: [null, 'grid'],
          gridTemplateAreas: [null, "'images text'"],
          gridTemplateColumns: [null, 'repeat(2, 1fr)'],
          columnGap: 3,
        }}
      >
        <Box
          mb={3}
          mx={-2}
          sx={{
            display: ['block', 'none'],
            gridArea: [null, 'images'],
            position: 'relative',
            '& .carousel-slider': {
              overflow: 'visible',
            },
            '& .slider-wrapper': {
              overflow: 'visible',
            },
          }}
        >
          <ImageCarousel images={merchImages} />
        </Box>
        <Box
          sx={{
            display: ['none', 'block'],
            gridArea: [null, 'images'],
          }}
        >
          <MerchImages images={merchImages} merch={merch} />
        </Box>
        <Box sx={{ gridArea: [null, 'text'] }}>
          <Heading as="h1" variant="title2" mb={1}>
            {name}
          </Heading>
          {subtitle && (
            <Text as="h2" variant="subtitle1" mb={[2, null, 3]}>
              {subtitle}
            </Text>
          )}
          <LinkedReviewStars id={slug} mb={[2, null, 3]} />
          {longDescription && (
            <Box
              dangerouslySetInnerHTML={{
                __html: longDescription.childMarkdownRemark.html,
              }}
              sx={{
                '& p': {
                  my: 2,
                  variant: 'text.body2',
                },
                '& p:first-of-type': {
                  mt: 0,
                },
                '& p:last-of-type': {
                  mb: 0,
                },
              }}
            />
          )}
          {availableSkus.length > 0 && (
            <Box
              mt={3}
              pt={3}
              sx={{
                borderTop: '1px solid',
                borderTopColor: 'greyLight',
              }}
            >
              {!soldOut && (
                <Fragment>
                  <MerchDetailsAddToCart merch={merch} />
                  {clubDiscount > 0 && (
                    <Box
                      bg="backgroundMedium"
                      mt={2}
                      py={2}
                      px={3}
                      sx={{ textAlign: 'center' }}
                    >
                      <Text as="p" variant="body3" m={0}>
                        {`Your ${clubDiscount}% club discount has been applied.`}
                      </Text>
                    </Box>
                  )}
                </Fragment>
              )}
              {soldOut && <ProductOutOfStock sku={availableSkus?.[0]?.sku} />}
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default MerchDetails
